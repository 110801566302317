<template>
  <data-list
    ref="dataList"
    class="content md"
    url="/admin/asset-water-receive"
    query="&sort=-id&expand=keTang"
    entity-name="桶装水"
    add-btn="添加记录"
    hide-show-detail
    :action="action"
    :rules="rules"
    :columns="columns"
    :custom-url="customUrl"
    :before-edit="beforeEdit"
    :before-update="beforeUpdate"
    :modal-form="{layout: 'horizontal', labelAlign: 'left', labelCol: {span: 6}, wrapperCol: {span: 18}}"
    :search-key-type="{name: 1}">
    <template v-slot:query="{form}">
      <a-form-model-item label="领用人">
        <select-book type="user" v-model="form.receiver" @change="updateList"></select-book>
      </a-form-model-item>
      <a-form-model-item label="堂口" prop="status">
        <a-select
          v-model="form.ketang_id"
          show-search
          placeholder="输入堂口名称搜索"
          style="width: 180px"
          allow-clear
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @change="updateList"
          @search="handleSearch">
          <a-select-option v-for="d in list" :key="d.id">{{ d.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="领用时间">
        <a-range-picker
          v-model="form.time"
          :placeholder="['开始时间', '结束时间']"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          @change="updateList"/>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{form}">
      <a-form-model-item label="领用人" prop="num">
        <select-book v-model="form.user" type="user"></select-book>
      </a-form-model-item>
      <a-form-model-item label="堂口" prop="status">
        <a-select
          v-model="form.ketang_id"
          show-search
          placeholder="输入堂口名称搜索"
          style="width: 100%"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearch">
          <a-select-option v-for="d in list" :key="d.id">{{ d.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="领用数量" prop="keep_name">
        <a-input-number :min="1" v-model="form.num" placeholder="请输入领用数量" style="width: 100%"></a-input-number>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
    import { throttle } from "../../common/js/tool";
    export default {
        name: "BottledWater",
        data() {
            return {
                columns: [
                    { title: '领用时间', dataIndex: 'create_time' },
                    { title: '领用人', dataIndex: 'receive_wx_user_id', customRender: text => <open-data type="userName" openid={text}></open-data> },
                    { title: '堂口', dataIndex: 'keTang',  customRender: text => text?.name || "--" },
                    { title: '领用数量', dataIndex: 'num' },
                ],
                list: [],
                rules: {
                    file: [{ required: true, message: '请上传文件', trigger: 'change' }],
                },
                methodOptions: [
                    { label: "企业微信通知", value: 1 },
                    { label: "短信通知", value: 2 },
                    { label: "语音电话通知", value: 3 },
                ]
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            },
        },
        created() {
            this.handleSearch = throttle(this.searchKt, 300)
        },
        methods: {
            updateList() {
                this.$refs.dataList.getList();
            },
            customUrl(form) {
                let res = "";
                if(form.receiver && form.receiver[0]) {
                    res += `&filter[receive_wx_user_id]=${form.receiver[0]}`;
                }
                if(form.ketang_id) {
                    res += `&filter[ketang_id]=${form.ketang_id}`;
                }
                if(form.time && form.time.length == 2) {
                    const start = form.time[0] + ' 00:00:00';
                    const end = form.time[1] + ' 23:59:59';
                    res += `&filter[create_time][gte]=${start}&filter[create_time][lt]=${end}`;
                }
                return res;
            },
            searchKt(value) {
                let url = "/admin/ketang"
                if(value) {
                    url += `?filter[name][like]=${value}`;
                }
                this.$axios(url).then(res => {
                    this.list = res.data;
                })
            },
            beforeEdit(form, callback) {
                if(form.id) {
                    form.user = form.receive_wx_user_id ? [form.receive_wx_user_id] :[];
                    this.list = [form.keTang];
                } else {
                    this.list = [];
                }
                callback();
            },
            beforeUpdate(form, callback) {
                form.receive_wx_user_id = form.user ? form.user[0] : null;
                callback();
            },

        }
    }
</script>

<style lang="less">

</style>
